import React from "react";
import { Card } from "react-bootstrap";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { Link } from "react-router-dom";
import "react-data-table-component-extensions/dist/index.css";
import { useState } from "react";
import { useEffect } from "react";
import ChatService from "../../services/chat.service";
import { CircularProgress } from "@mui/material";
import withLoader from "../../layout/Loader/WithLoader";

const Report = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState("");

  const getAllReports = async () => {
    try {
      setLoading(true);
      const response = await ChatService.getAllReports();
      setData(response.data.data);
      console.log(response);
      setLoading(false);
    } catch (error) {
      setData([]);
      console.log(error);
    }
  };

  useEffect(() => {
    getAllReports();
  }, []);

  const columns = [
    {
      name: <strong>TICKET NUMBER</strong>,
      selector: (row) => [row.ticketNumber],
      sortable: true,
    },
    {
      name: <strong>COUNSELLOR NAME</strong>,
      selector: (row) => [
        row.counsellor?.CounsellerName !== undefined &&
        row.counsellor?.CounsellerName !== null &&
        row.counsellor?.CounsellerName !== ""
          ? row.counsellor.CounsellerName
          : "--",
      ],
      sortable: true,
    },
    {
      name: <strong>USER NAME</strong>,
      selector: (row) => [
        row.user?.fullname !== undefined &&
        row.user?.fullname !== null &&
        row.user?.fullname !== ""
          ? row.user.fullname
          : "--",
      ],
      sortable: true,
    },
    {
      name: <strong>REPORT REASON</strong>,
      selector: (row) => [row.reason ? row.reason : "--"],
      sortable: true,
    },
    {
      name: <strong>DESCRIPTION</strong>,
      selector: (row) => [row.description ? row.description : "--"],
      sortable: true,
    },
    {
      name: <strong>ACTION</strong>,
      selector: (row) => (
        <Link
          className="btn btn-primary p-1 btn-sm"
          id={row.VIEW}
          to={`/report/chat/${row.id}`}
        >
          <i className="fe fe-eye"></i> View
        </Link>
      ),
      sortable: true,
    },
  ];

  const tableData = {
    columns,
    data,
  };
  return (
    <div className="page-header">
      <h2 className="main-content-title tx-24 mb-3 mt-2">Report</h2>
      <Card>
        <div className="d-flex justify-content-end"></div>
        <div className="p-3">
          <DataTableExtensions {...tableData}>
            <DataTable
              title="Reports"
              columns={columns}
              data={data}
              noHeader
              defaultSortField="id"
              defaultSortAsc={false}
              striped={true}
              center={true}
              persistTableHead
              pagination
              highlightOnHover
              progressPending={loading}
              progressComponent={
                <CircularProgress
                  size={30}
                  style={{ textAlign: "center", marginTop: "20px" }}
                />
              }
            />
          </DataTableExtensions>
        </div>
      </Card>
    </div>
  );
};

export default withLoader(Report);
