import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get } from "lodash";
// import AuthenticationService from '../../../src/services/auth.service';
import AuthenticationService from "../../app/services/auth.service";
import { db } from "../../firebase";
import { doc, setDoc } from "firebase/firestore";

export const signIn = createAsyncThunk(
  "/admin/login-admin",
  async (requestObj, thunkAPI) => {
    try {
      const response = await AuthenticationService.signIn(requestObj);
      const adminId = response.Admin.id;
      console.log("response", response);
      localStorage.setItem("adminId", adminId);

      await setDoc(doc(db, "admin", adminId), { adminId });

      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(
        get(error, "response.data.apierror", error)
      );
    }
  }
);

export const logout = createAsyncThunk("auth/logout", () => {
  AuthenticationService.logout();
});

const initialState = {
  loading: false,
  token: null,
  error: false,
  user: null,
  isLoggedIn: !!JSON.parse(localStorage.getItem("isLoggedIn")),
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLoggedIn: (state, action) => {
      state.isLoggedIn = action.payload;
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
      state.token = get(action.payload, "token", null);
      localStorage.setItem(
        "token",
        JSON.stringify(get(action.payload, "token", null))
      );
    },
  },
  extraReducers: {
    [signIn.pending]: (state) => {
      state.loading = true;
    },
    [signIn.fulfilled]: (state, action) => {
      state.token = action.payload;
      state.loading = false;
    },
    [signIn.rejected]: (state) => {
      state.error = true;
      state.loading = false;
    },
    [logout.fulfilled]: (state) => {
      state.token = null;
    },
  },
});

const { reducer, actions } = authSlice;

export const { setToken, setUser, setLoggedIn } = actions;
export default reducer;
