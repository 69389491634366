import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFirestore, doc, setDoc } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyA_O2ndFU327rblXB2D3wecRu_ZpZNZ41I",
  authDomain: "backergysoft-cddde.firebaseapp.com",
  projectId: "backergysoft-cddde",
  storageBucket: "backergysoft-cddde.appspot.com",
  messagingSenderId: "336431037490",
  appId: "1:336431037490:web:fb2e1a2198c047ff64502b",
  measurementId: "G-HDFERW6XBD"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

//initialize Cloud Functions through Firebase
const auth = getAuth();

const storage = getStorage();

const db = getFirestore(app);

export { app, auth, db, doc, setDoc, storage };