import React, { Fragment, useEffect, useRef, useState } from "react";
import { Button, Card, Dropdown } from "react-bootstrap";
import { useLocation, useParams } from "react-router";
import Avtar from "../../../assets/img/avatar.png";
import moment from "moment";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link } from "react-router-dom";
import { ReactMic } from "react-mic";
import { CircularProgress } from "@mui/material";
import ChatService from "../../services/chat.service";
import {
  collection,
  doc,
  onSnapshot,
  orderBy,
  query,
  setDoc,
} from "firebase/firestore";
import { getDownloadURL, ref, uploadBytesResumable } from "@firebase/storage";
import { db, storage } from "../../../firebase";
import withLoader from "../../layout/Loader/WithLoader";
import UserService from "../../services/user.service";
import CounsellorService from "../../services/counsellor.service";

const GroupChat = () => {
  const { id } = useParams();
  const [counsellorData, setCounsellorData] = useState("");
  const [allMessages, setAllMessages] = useState([]);
  const [userData, setUserData] = useState("");
  const [sentDate, setSentDate] = useState("");
  const [img, setImg] = useState(null);
  const [audio, setAudio] = useState(null);
  const [recordedBlob, setRecordedBlob] = useState(null);
  const [recordAudio, setRecordAudio] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [chatMessage, setChatMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [isFirst, setIsFirst] = useState(true);
  const [counsellorId, setCounsellorId] = useState();
  const audioFileRef = useRef(null);

  const { state } = useLocation();
  // const { senderId, receiverId } = state;
  const senderId = state?.senderId;
  const receiverId = state?.receiverId;

  console.log(">>>>>>>>>>>>>>>>>>>>>>", senderId);
  const isCounsellorChat = senderId === counsellorId;
  const getAllMessages = async () => {
    const unsub = onSnapshot(
      query(
        collection(db, "groupMessages", `${id}`, `messages`),
        orderBy("timestamp")
      ),
      (snapshot) => {
        let chatList = [];
        snapshot.forEach((doc) => {
          const data = doc.data();
          chatList.push(data);
          setSentDate(data.timestamp);
        });
        setAllMessages(chatList);
      }
    );
    return unsub;
  };

  useEffect(() => {
    getAllMessages();
  }, []);

  const generateUniqueId = () => {
    const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
    let id = "";
    for (let i = 0; i < 20; i++) {
      const randomIndex = Math.floor(Math.random() * alphabet.length);
      id += alphabet.charAt(randomIndex);
    }
    return id;
  };

  const sendMessage = async () => {
    if (isFirst) {
      const response = await ChatService.chatStatus(id, "Responded");
      console.log(response);
      setIsFirst(false);
    }
    try {
      const timestamp = new Date().getTime();
      const handleUpload = async (storageRef, type) => {
        const uploadTask = uploadBytesResumable(
          storageRef,
          type === "img" ? img : type === "audio" ? audio : recordedBlob
        );

        uploadTask.on(
          "state_changed",
          (snapshot) => {},
          (error) => {
            console.log("Error uploading image:", error);
          },
          async () => {
            setLoading(true);
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            if (downloadURL.length > 0) {
              const uniqueId = generateUniqueId();
              await setDoc(
                doc(db, `groupMessages/${id}/messages/${uniqueId}`),
                {
                  idFrom: senderId,
                  idTo: receiverId,
                  content: downloadURL,
                  timestamp: `${timestamp}`,
                  type: type === "img" ? "3" : "2",
                }
              );
            }
            setLoading(false);
          }
        );
      };

      if (img) {
        const storageRef = ref(
          storage,
          "/pictures/" + img.name + "-" + timestamp
        );
        await handleUpload(storageRef, "img");
      } else if (audio) {
        const storageRef = ref(
          storage,
          "/Voice-Recording/" + audio.name + "-" + timestamp
        );
        await handleUpload(storageRef, "audio");
      } else if (recordedBlob) {
        const storageRef = ref(
          storage,
          "/Voice-Recording/" + recordedBlob.name + "-" + timestamp
        );
        await handleUpload(storageRef, "recordedBlob");
      } else {
        if (chatMessage.length > 0) {
          setLoading(true);
          const uniqueId = generateUniqueId();
          await setDoc(doc(db, `groupMessages/${id}/messages/${uniqueId}`), {
            idFrom: senderId,
            idTo: receiverId,
            content: chatMessage,
            timestamp: `${timestamp}`,
            type: "1",
          });
        }
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
    setChatMessage("");
    setImg(null);
    setAudio(null);
    setRecordedBlob(null);
    setRecordAudio(false);
  };
  const getUserById = async () => {
    const response = await UserService.getUserById(receiverId);
    setUserData(response.data.data);
    console.log("UserData", response);
  };

  const getCounsellorById = async () => {
    const response = await CounsellorService.getCounsellorProfile(senderId);
    setCounsellorData(response.data.data);
    setCounsellorId(response.data.data.id);
    console.log(senderId === response.data.data.id);
    console.log("counsellorData", response);
  };
  useEffect(() => {
    getCounsellorById();
    getUserById();
  }, [receiverId, senderId]);

  useEffect(() => {
    const fetchData = async () => {
      if (isCounsellorChat) {
        const response = await CounsellorService.getCounsellorProfile(senderId);
        console.log("response counsellor", response);
        setCounsellorData(response.data.data);
        setCounsellorId(response.data.data.id);
        const newResponse = await UserService.getUserById(receiverId);
        console.log("newResponse counsellor", newResponse);
        setUserData(newResponse.data.data);
        // console.log("USERDATA", userData);
      } else {
        const newResponse = await CounsellorService.getCounsellorProfile(
          receiverId
        );
        setCounsellorData(newResponse.data.data);
        console.log("newResponse user", newResponse);

        const response = await UserService.getUserById(senderId);
        console.log("response user", response);
        setUserData(response.data.data);
        // console.log("USERDATA", userData);
      }
    };

    fetchData();
  }, [senderId, receiverId, counsellorId, isCounsellorChat]);

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      sendMessage();
    }
  };
  const recordedAudio = () => {
    setRecordAudio(true);
  };

  const uploadedAudio = () => {
    audioFileRef.current.click();
  };
  const startRecording = () => {
    setIsRecording(true);
  };

  const stopRecording = () => {
    setIsRecording(false);
  };
  const onRecordingComplete = (recordedBlob) => {
    setRecordedBlob(recordedBlob.blob);
  };

  const deleteSelectedImages = () => {
    setImg(null);
    setAudio(null);
    setRecordedBlob(false);
    setRecordAudio(false);
  };

  const today = moment().format("DD-MM-YYYY");
  const yesterday = moment().subtract(1, "days").format("DD-MM-YYYY");
  const headingDateTimestamp = moment(Number(sentDate)).format("DD-MM-YYYY");

  let dateHeading = "";
  if (headingDateTimestamp == today) {
    dateHeading = "Today";
  } else if (headingDateTimestamp == yesterday) {
    dateHeading = "Yesterday";
  } else {
    dateHeading = headingDateTimestamp;
  }

  return (
    <>
      <Fragment>
        <div className="page-header">
          <h2 className="main-content-title tx-24 mg-b-5 mb-3">Chats</h2>
          <Card className="custom-card">
            <div className="main-content-app pt-0">
              <div className="main-content-body main-content-body-chat">
                <div className="main-chat-header pt-3">
                  <div className="main-img-user online">
                    <img
                      alt="avatar"
                      src={
                        !isCounsellorChat
                          ? counsellorData.counsellorImg || Avtar
                          : userData.UserImage || Avtar
                      }
                    />
                  </div>
                  <div className="main-chat-msg-name">
                    <h6>
                      {!isCounsellorChat
                        ? counsellorData.CounsellerName || "--"
                        : userData.fullname || "--"}
                    </h6>
                    <span
                      className={`dot-label ${
                        !isCounsellorChat
                          ? counsellorData.isonline
                            ? "bg-success"
                            : "bg-danger"
                          : userData.online
                          ? "bg-success"
                          : "bg-danger"
                      }`}
                    ></span>
                    <small className="me-3">
                      {!isCounsellorChat
                        ? counsellorData.isonline
                          ? "Online"
                          : "Offline"
                        : userData.online
                        ? "Online"
                        : "Offline"}
                    </small>
                    <div>
                      <small>
                        Last Seen{" "}
                        {moment(new Date()).format("DD/MM/YYYY") ===
                        moment(
                          !isCounsellorChat
                            ? counsellorData.offlineTime
                            : userData.offlineTime
                        ).format("DD/MM/YYYY")
                          ? "Today"
                          : moment(
                              !isCounsellorChat
                                ? counsellorData.offlineTime
                                : userData.offlineTime
                            ).format("DD/MM/YYYY hh:mm a")}
                      </small>{" "}
                    </div>
                  </div>
                </div>
                {/* <!-- main-chat-header --> */}

                <div className="main-chat-body" id="ChatBody">
                  <PerfectScrollbar
                    style={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      flexDirection: "column-reverse",
                    }}
                  >
                    {allMessages
                      .slice()
                      .reverse()
                      .map((msg, index) => (
                        <>
                          <div className="content-inner" key={index}>
                            {msg.idFrom === senderId ? (
                              <div className="media flex-row-reverse">
                                <div className="main-img-user online">
                                  <img
                                    alt="avatar"
                                    src={
                                      msg.idFrom === senderId
                                        ? isCounsellorChat
                                          ? counsellorData.counsellorImg ||
                                            Avtar
                                          : userData.UserImage || Avtar
                                        : isCounsellorChat
                                        ? userData.UserImage || Avtar
                                        : counsellorData.counsellorImg || Avtar
                                    }
                                  />
                                </div>
                                <div className="media-body">
                                  <div className="main-msg-wrapper">
                                    {msg.type === "1" && msg.content}
                                    {msg.type === "3" && (
                                      <div>
                                        <img
                                          src={msg.content}
                                          alt="chat-img"
                                          width="250px"
                                        />
                                      </div>
                                    )}
                                    {msg.type === "2" && (
                                      <div>
                                        <audio controls>
                                          <source
                                            src={msg.content}
                                            type="audio/mp3"
                                          />
                                        </audio>
                                      </div>
                                    )}
                                  </div>

                                  <div>
                                    <span>
                                      {moment(Number(msg.timestamp)).format(
                                        "hh:mm A"
                                      )}
                                    </span>
                                    <Link to="#">
                                      <i className="icon ion-android-more-horizontal"></i>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div className="media">
                                <div className="main-img-user online">
                                  <img
                                    alt="avatar"
                                    src={
                                      msg.idFrom === senderId
                                        ? isCounsellorChat
                                          ? counsellorData.counsellorImg ||
                                            Avtar
                                          : userData.UserImage || Avtar
                                        : isCounsellorChat
                                        ? userData.UserImage || Avtar
                                        : counsellorData.counsellorImg || Avtar
                                    }
                                  />
                                </div>
                                <div className="media-body">
                                  <div className="main-msg-wrapper">
                                    {msg.type === "1" && msg.content}
                                    {msg.type === "3" && (
                                      <div>
                                        <img
                                          src={msg.content}
                                          alt="chat-img"
                                          width="200px"
                                        />
                                      </div>
                                    )}
                                    {msg.type === "2" && (
                                      <div>
                                        <audio controls>
                                          <source
                                            src={msg.content}
                                            type="audio/mp3"
                                          />
                                          Your browser does not support the
                                          audio element.
                                        </audio>
                                      </div>
                                    )}
                                  </div>
                                  <div>
                                    <span>
                                      {moment(Number(msg.timestamp)).format(
                                        "hh:mm A"
                                      )}
                                    </span>
                                    <Link to="#">
                                      <i className="icon ion-android-more-horizontal"></i>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </>
                      ))}
                    <label className="main-chat-time mt-2">
                      <span>{dateHeading}</span>
                    </label>
                  </PerfectScrollbar>
                </div>
                <div className="d-flex justify-content-center">
                  {img && (
                    <div
                      key={img}
                      className="image"
                      style={{ cursor: "pointer" }}
                    >
                      <Button
                        className="btn btn-ptimary ms-auto"
                        onClick={deleteSelectedImages}
                        style={{
                          position: "absolute",
                          zIndex: 1,
                          width: "10px",
                          height: "30px",
                        }}
                      >
                        <i
                          className="fa fa-times"
                          style={{ marginLeft: "-5px", marginBottom: "3px" }}
                        ></i>
                      </Button>
                      <img
                        src={URL.createObjectURL(img)}
                        alt="upload"
                        style={{
                          position: "relative",
                        }}
                        width="150px"
                        className="img-responsive2"
                      />
                    </div>
                  )}
                  {audio && (
                    <div
                      key={audio}
                      className="image"
                      style={{ cursor: "pointer" }}
                    >
                      <Button
                        className="btn btn-ptimary ms-auto"
                        onClick={deleteSelectedImages}
                        style={{
                          position: "absolute",
                          zIndex: 1,
                          width: "10px",
                          height: "30px",
                        }}
                      >
                        <i
                          className="fa fa-times"
                          style={{ marginLeft: "-5px", marginBottom: "3px" }}
                        ></i>
                      </Button>
                      <audio
                        src={URL.createObjectURL(audio)}
                        controls
                        style={{
                          position: "relative",
                        }}
                        className="audio-responsive"
                      />
                    </div>
                  )}
                  {recordedBlob && (
                    <div>
                      <Button
                        className="btn btn-ptimary ms-auto"
                        onClick={deleteSelectedImages}
                        style={{
                          position: "absolute",
                          zIndex: 1,
                          width: "10px",
                          height: "30px",
                        }}
                      >
                        <i
                          className="fa fa-times"
                          style={{ marginLeft: "-5px", marginBottom: "3px" }}
                        ></i>
                      </Button>
                      <audio
                        controls
                        src={recordedBlob.blob}
                        style={{
                          position: "relative",
                        }}
                      />
                    </div>
                  )}
                </div>
                <div className="d-flex justify-content-center">
                  {recordAudio && (
                    <div>
                      <Button
                        className="btn btn-ptimary ms-auto"
                        onClick={deleteSelectedImages}
                        style={{
                          position: "absolute",
                          zIndex: 1,
                          width: "10px",
                          height: "30px",
                        }}
                      >
                        <i
                          className="fa fa-times"
                          style={{ marginLeft: "-5px", marginBottom: "3px" }}
                        ></i>
                      </Button>
                      <ReactMic
                        record={isRecording}
                        onStop={onRecordingComplete}
                        mimeType="audio/webm"
                        visualSetting="sinewave"
                        strokeColor={"#121255"}
                      />
                      <button
                        className="btn"
                        onClick={startRecording}
                        disabled={isRecording}
                      >
                        <i className="fa fa-microphone text-muted"></i>
                      </button>
                      <button
                        className="btn"
                        onClick={stopRecording}
                        disabled={!isRecording}
                      >
                        <i className="fa fa-microphone-slash text-muted"></i>
                      </button>
                    </div>
                  )}
                </div>

                <div className="main-chat-footer">
                  <nav className="nav">
                    <div>
                      <input
                        type="file"
                        id="file"
                        style={{ display: "none" }}
                        onChange={(e) => setImg(e.target.files[0])}
                      />
                      <label
                        style={{ cursor: "pointer" }}
                        className="me-3 mt-2"
                        htmlFor="file"
                      >
                        <i className="fe fe-image nav-link"></i>
                      </label>
                    </div>

                    <Dropdown className="main-profile-menu-1">
                      <Dropdown.Toggle className="d-flex p-0" variant="default">
                        <i className="fa fa-microphone text-muted"></i>
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="dropdown-menu-chat">
                        <Dropdown.Item
                          onClick={() => {
                            uploadedAudio();
                          }}
                        >
                          <label
                            style={{ cursor: "pointer" }}
                            htmlFor="audio-file"
                          >
                            Upload Audio
                          </label>
                        </Dropdown.Item>
                        <Dropdown.Item onClick={recordedAudio}>
                          <div>Record Audio</div>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    <div style={{ cursor: "pointer" }}>
                      <input
                        type="file"
                        id="audio-file"
                        ref={audioFileRef}
                        style={{ display: "none" }}
                        accept="audio/*"
                        onChange={(e) => setAudio(e.target.files[0])}
                      />
                    </div>
                  </nav>

                  <input
                    className="form-control"
                    placeholder="Type your message here..."
                    type="text"
                    value={chatMessage}
                    onChange={(e) => setChatMessage(e.target.value)}
                    onKeyPress={handleKeyPress}
                  />
                  {!loading ? (
                    <>
                      <Link className="main-msg-send" onClick={sendMessage}>
                        <i className="far fa-paper-plane"></i>
                      </Link>
                    </>
                  ) : (
                    <div className="d-flex justify-content-center">
                      <CircularProgress />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Card>
        </div>
      </Fragment>
    </>
  );
};

export default withLoader(GroupChat);
