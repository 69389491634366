import { getDocs, query } from "firebase/firestore";
import {  db } from "../../firebase";
import { collection } from "firebase/firestore";
import serverCall from "../../serverCall";

const getAllReports = () => {
  const response = serverCall.get(`/report-user/admin`);
  return response;
};

const getReportById = (id) => {
  const response = serverCall.get(`/report-user/${id}`);
  return response;
};

const chatStatus = (id, status) => {
  const response = serverCall.post(
    `/report-user/update-status?id=${id}&status=${status}`
  );
  return response;
};

const getChatById = async (id, chatUser1, messages) => {
  const chatRef = collection(db, `users1`, id, chatUser1, messages);
  const q = query(chatRef);

  const querysnap = await getDocs(q);

  return querysnap;
};

const ChatService = {
  getAllReports,
  chatStatus,
  getReportById,
  getChatById,
};

export default ChatService;
