import { InputAdornment } from "@mui/material";
import { collection, onSnapshot, query } from "firebase/firestore";
import React, { Fragment, useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { db } from "../../../firebase";
import withLoader from "../../layout/Loader/WithLoader";
import Profile from "../../../assets/img/user_icon.png";

const UserChatList = () => {
  const { userId } = useParams();
  console.log("counsellorId", userId);

  const [chatData, setChatData] = useState([]);
  const [searchString, setSearchString] = useState("");
  const handlesearch = (e) => {
    setSearchString(e.target.value);
  };

  const getList = async () => {
    const unsub = onSnapshot(query(collection(db, "users")), (snapshot) => {
      const chatList = [];
      snapshot.forEach((doc) => {
        const data = doc.data();
        if (data.id === userId) {
          if (data.friends) {
            console.log("dataaaa", data.friends);
            const chatRoomValues = Object.values(data.friends);
            chatRoomValues.map((chat) => {
              console.log("Matched object:", chat);
              chatList.push(chat);
            });
          }
        }
      });
      setChatData(chatList);
    });
    return unsub;
  };

  console.log(chatData);

  useEffect(() => {
    getList();
  }, []);

  return (
    <Fragment>
      <div className="page-header">
        <h2 className="main-content-title tx-28 mt-2">Chatlist</h2>

        <Card>
          <Card.Body>
            {chatData.length > 0 ? (
              <>
                <div className="d-flex add-search">
                  <div className="d-flex align-items-center md-form mt-auto mb-2">
                    <input
                      type="search"
                      className="form-control form-control-md ml-3 py-3"
                      value={searchString}
                      onChange={handlesearch}
                      placeholder="Search"
                      inputprops={{
                        startAdornment: (
                          <InputAdornment position="start"></InputAdornment>
                        ),
                      }}
                    ></input>
                    <i className="fa fa-search ms-2"></i>
                  </div>
                </div>
                <div className="chats">
                  {chatData
                    .filter((row) => {
                      const combinedString = `${row.name} ${row.id} ${row.lastMessage}`;
                      return combinedString
                        .toLowerCase()
                        .includes(searchString);
                    })
                    // .sort((a, b) => b.lastMessageTime - a.lastMessageTime)
                    .map((chat) => (
                      <Link
                        key={chat.id}
                        className="userChat"
                        to={`/groupchat/${userId}-${chat.id}`}
                        state={{
                          senderId: userId,
                          receiverId: chat.id,
                        }}
                      >
                        <img
                          src={chat.profile ? chat.profile : Profile}
                          alt="Profile"
                        />
                        <div className="userChatInfo">
                          <span>{chat.name}</span>
                          <p
                            className="mb-0 text-muted"
                            style={{ fontSize: "12px" }}
                          >
                            {chat.lastMessage}
                          </p>
                        </div>
                      </Link>
                    ))}
                </div>
              </>
            ) : (
              <div className="text-danger tx-20 text-center">
                Chat Not Found
              </div>
            )}
          </Card.Body>
        </Card>
      </div>
    </Fragment>
  );
};

export default withLoader(UserChatList);
