import serverCall from "../../serverCall";

const getAllUsers = () => {
  const response = serverCall.get(`/users/all-users/admin`);
  return response;
};

const getUserById = (userId) => {
  const response = serverCall.get(`/users/admin/getUserDetails?id=${userId}`);
  return response;
};

const RestrictUser = (userId, userRestrict) => {
  const response = serverCall.post(
    `/users/RestrictedUser/admin?userId=${userId}&isRestricted=${userRestrict}`
  );
  return response;
};

const updateUser = (id, data) => {
  const response = serverCall.put(
    `/users/admin/update-user-details?userId=${id}`,
    data
  );
  return response;
};

const UserService = {
  getAllUsers,
  getUserById,
  RestrictUser,
  updateUser,
};

export default UserService;
