import { Button, Card, Dropdown } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-data-table-component-extensions/dist/index.css";
import { Link, useParams } from "react-router-dom";
import React, { Fragment, useRef } from "react";
import ChatService from "../../services/chat.service";
import { useEffect } from "react";
import { useState } from "react";
import Avtar from "../../../assets/img/avatar.png";

import { db, storage } from "../../../firebase";
import {
  collection,
  doc,
  onSnapshot,
  orderBy,
  query,
  setDoc,
} from "firebase/firestore";
import moment from "moment";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { ReactMic } from "react-mic";
import { CircularProgress } from "@mui/material";
import withLoader from "../../layout/Loader/WithLoader";
import { toast } from "react-toastify";

const ViewChats = () => {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [allMessages, setAllMessages] = useState([]);
  const [chatMessage, setChatMessage] = useState("");
  const [senderId, setsenderId] = useState("");
  const [groupName, setGroupName] = useState("");
  const [img, setImg] = useState(null);
  const [audio, setAudio] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [recordedBlob, setRecordedBlob] = useState(null);
  const [recordAudio, setRecordAudio] = useState(false);
  const [sentDate, setSentDate] = useState("");
  const [isFirst, setIsFirst] = useState(true);
  const audioFileRef = useRef(null);

  const adminId = localStorage.getItem("adminId");
  console.log("adminId", adminId)

  const recordedAudio = () => {
    setRecordAudio(true);
  };

  const uploadedAudio = () => {
    audioFileRef.current.click();
  };

  const startRecording = () => {
    setIsRecording(true);
  };

  const stopRecording = () => {
    setIsRecording(false);
  };

  const onRecordingComplete = (recordedBlob) => {
    setRecordedBlob(recordedBlob.blob);
  };

  const getReportById = async () => {
    const response = await ChatService.getReportById(id);
    setData(response.data.data);
    setsenderId(
      response.data.data.counsellerId
        ? response.data.data.counsellerId
        : response.data.data.userId
    );
    console.log(response);

    const receiverId =
      response.data.data.userType === "Counsellor"
        ? response.data.data.counsellerId
        : response.data.data.userId;

    const ticketId = receiverId + "=" + adminId + "=" + response.data.data.id;

    setGroupName(ticketId);
    console.log("ticketId", ticketId);

    await getAllMessages(ticketId);
  };

  const getAllMessages = async (onetoone) => {
    const unsub = onSnapshot(
      query(
        collection(
          db,
          "supportchat",
          `${onetoone}`, // sender id
          `messages`
        ),
        orderBy("timestamp")
      ),
      (snapshot) => {
        let chatList = [];
        snapshot.forEach((doc) => {
          const data = doc.data();
          chatList.push(data);
          setSentDate(data.timestamp);
        });
        setAllMessages(chatList);
      }
    );
    return unsub;
  };

  useEffect(() => {
    getReportById();
  }, []);

  console.log(allMessages);

  const inReview = async () => {
    const response = await ChatService.chatStatus(id, "In review");
    console.log(response);
    toast.warning("Report is In Review!", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
    getReportById();
  };

  const resolve = async () => {
    const response = await ChatService.chatStatus(id, "Resolved");
    console.log(response);
    toast.success("Report Has Been Resolved!", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
    getReportById();
  };

  const sendMessage = async () => {
    if (isFirst) {
      const response = await ChatService.chatStatus(id, "Responded");
      console.log(response);
      getReportById();
      setIsFirst(false);
    }
    console.log("isFirstTime", isFirst);
    try {
      const timestamp = new Date().getTime();
      const handleUpload = async (storageRef, type) => {
        const uploadTask = uploadBytesResumable(
          storageRef,
          type === "img" ? img : type === "audio" ? audio : recordedBlob
        );

        uploadTask.on(
          "state_changed",
          (snapshot) => { },
          (error) => {
            console.log("Error uploading image:", error);
          },
          async () => {
            setLoading(true);
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            if (downloadURL.length > 0) {
              await setDoc(
                doc(db, `supportchat/${groupName}/messages/${timestamp}`),
                {
                  idFrom: adminId,
                  content: downloadURL,
                  idTo: senderId,
                  timestamp: `${timestamp}`,
                  type: type === "img" ? "3" : "2",
                }
              );
            }
            setLoading(false);
          }
        );
      };

      if (img) {
        const storageRef = ref(
          storage,
          "/pictures/" + img.name + "-" + timestamp
        );
        await handleUpload(storageRef, "img");
      } else if (audio) {
        const storageRef = ref(
          storage,
          "/Voice-Recording/" + audio.name + "-" + timestamp
        );
        await handleUpload(storageRef, "audio");
      } else if (recordedBlob) {
        const storageRef = ref(
          storage,
          "/Voice-Recording/" + recordedBlob.name + "-" + timestamp
        );
        await handleUpload(storageRef, "recordedBlob");
      } else {
        if (chatMessage.length > 0) {
          setLoading(true);

          await setDoc(
            doc(db, `supportchat/${groupName}/messages/${timestamp}`),
            {
              idFrom: adminId,
              idTo: senderId, //sender id
              content: chatMessage,
              timestamp: `${timestamp}`,
              type: "1",
            }
          );
        }
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
    setChatMessage("");
    setImg(null);
    setAudio(null);
    setRecordedBlob(null);
    setRecordAudio(false);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      sendMessage();
    }
  };

  const deleteSelectedImages = () => {
    setImg(null);
    setAudio(null);
    setRecordedBlob(false);
    setRecordAudio(false);
  };

  const today = moment().format("DD-MM-YYYY");
  const yesterday = moment().subtract(1, "days").format("DD-MM-YYYY");
  const headingDateTimestamp = moment(Number(sentDate)).format("DD-MM-YYYY");

  let dateHeading = "";
  if (headingDateTimestamp == today) {
    dateHeading = "Today";
  } else if (headingDateTimestamp == yesterday) {
    dateHeading = "Yesterday";
  } else {
    dateHeading = headingDateTimestamp;
  }

  return (
    <Fragment>
      <div className="page-header">
        <h2 className="main-content-title tx-24 mg-b-5 mb-3">Chats</h2>
        {/* <Col sm={12} md={12} lg={7} xl={6}> */}
        <Card className="custom-card">
          <div className="main-content-app pt-0">
            <div className="main-content-body main-content-body-chat">
              <div className="main-chat-header pt-3">
                {data.userType === "User" && (
                  <>
                    <div className="main-img-user online">
                      <img
                        alt="avatar"
                        src={
                          data.user?.UserImage !== undefined &&
                            data.user?.UserImage !== null &&
                            data.user?.UserImage !== ""
                            ? data.user.UserImage
                            : Avtar
                        }
                      />
                    </div>
                    <div className="main-chat-msg-name">
                      <h6>
                        {data.user?.fullname !== undefined &&
                          data.user?.fullname !== null &&
                          data.user?.fullname !== ""
                          ? data.user.fullname
                          : "--"}
                      </h6>
                      <span
                        className={`dot-label ${data.user?.online !== undefined &&
                          data.user?.online !== null &&
                          data.user?.online !== ""
                          ? data.user.online
                            ? "bg-success"
                            : "bg-danger"
                          : "--"
                          }`}
                      ></span>
                      <small className="me-3">
                        {data.user?.online !== undefined &&
                          data.user?.online !== null &&
                          data.user?.online !== ""
                          ? data.user.online
                            ? "Online"
                            : "Offline"
                          : "--"}
                      </small>
                      <div>
                        <small>Ticket Number : {data.ticketNumber}</small>
                      </div>
                      <div>
                        <small>Reason : {data.reason}</small>
                      </div>
                    </div>
                  </>
                )}
                {data.userType === "Counsellor" && (
                  <>
                    <div className="main-img-user online">
                      <img
                        src={
                          data.counsellor?.profile !== undefined &&
                            data.counsellor?.profile !== null &&
                            data.counsellor?.profile !== ""
                            ? data.counsellor.profile
                            : Avtar
                        }
                      />
                    </div>
                    <div className="main-chat-msg-name">
                      <h6>
                        {data.counsellor?.CounsellerName !== undefined &&
                          data.counsellor?.CounsellerName !== null &&
                          data.counsellor?.CounsellerName !== ""
                          ? data.counsellor.CounsellerName
                          : "--"}
                      </h6>
                      <span
                        className={`dot-label ${data.counsellor?.isonline !== undefined &&
                          data.counsellor?.isonline !== null &&
                          data.counsellor?.isonline !== ""
                          ? data.counsellor.isonline
                            ? "bg-success"
                            : "bg-danger"
                          : "--"
                          }`}
                      ></span>
                      <small className="me-3">
                        {data.counsellor?.isonline !== undefined &&
                          data.counsellor?.isonline !== null &&
                          data.counsellor?.isonline !== ""
                          ? data.counsellor.isonline
                            ? "Online"
                            : "Offline"
                          : "--"}
                      </small>
                    </div>
                  </>
                )}
                {data.status === "In Queue" && (
                  <div className="d-flex ms-auto mt-5">
                    <button className="btn btn-dark btn-sm">
                      {data.status}
                    </button>
                  </div>
                )}

                {data.status === "Responded" && (
                  <div className="d-flex ms-auto mt-5">
                    <button
                      className="btn btn-info btn-sm me-2"
                      onClick={inReview}
                    >
                      In review
                    </button>
                    <button
                      className="btn btn-warning btn-sm"
                      onClick={resolve}
                    >
                      Resolve
                    </button>
                  </div>
                )}

                {data.status === "In review" && (
                  <div className="d-flex ms-auto mt-5">
                    <button className="btn btn-info btn-sm" onClick={resolve}>
                      Resolve
                    </button>
                  </div>
                )}

                {data.status === "Resolved" && (
                  <div className="d-flex ms-auto mt-5">
                    <button className="btn btn-success btn-sm">
                      {data.status}
                    </button>
                  </div>
                )}
              </div>
              {/* <!-- main-chat-header --> */}

              <div className="main-chat-body" id="ChatBody">
                <PerfectScrollbar
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column-reverse",
                  }}
                >
                  {allMessages
                    .slice()
                    .reverse()
                    .map((msg, index) => (
                      <>
                        <div className="content-inner" key={index}>
                          {msg.idFrom === adminId ? (
                            <div className="media flex-row-reverse">
                              <div className="main-img-user online">
                                <img
                                  alt="avatar"
                                  src={require("../../../assets/img/avatar.png")}
                                />
                              </div>
                              <div className="media-body">
                                <div className="main-msg-wrapper">
                                  {msg.type === "1" && msg.content}
                                  {msg.type === "3" && (
                                    <div>
                                      <img
                                        src={msg.content}
                                        alt="chat-img"
                                        width="250px"
                                      />
                                    </div>
                                  )}
                                  {msg.type === "2" && (
                                    <div>
                                      <audio controls>
                                        <source
                                          src={msg.content}
                                          type="audio/mp3"
                                        />
                                      </audio>
                                    </div>
                                  )}
                                </div>

                                <div>
                                  <span>
                                    {moment(Number(msg.timestamp)).format(
                                      "hh:mm A"
                                    )}
                                  </span>
                                  <Link to="#">
                                    <i className="icon ion-android-more-horizontal"></i>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="media">
                              <div className="main-img-user online">
                                <img
                                  alt="avatar"
                                  src={
                                    data.userType === "User"
                                      ? data.user?.UserImage !== undefined &&
                                        data.user?.UserImage !== null &&
                                        data.user?.UserImage !== ""
                                        ? data.user.UserImage
                                        : Avtar
                                      : data.counsellor?.profile !==
                                        undefined &&
                                        data.counsellor?.profile !== null &&
                                        data.counsellor?.profile !== ""
                                        ? data.counsellor.profile
                                        : Avtar
                                  }
                                />
                              </div>
                              <div className="media-body">
                                <div className="main-msg-wrapper">
                                  {msg.type === "1" && msg.content}
                                  {msg.type === "3" && (
                                    <div>
                                      <img
                                        src={msg.content}
                                        alt="chat-img"
                                        width="200px"
                                      />
                                    </div>
                                  )}
                                  {msg.type === "2" && (
                                    <div>
                                      <audio controls>
                                        <source
                                          src={msg.content}
                                          type="audio/mp3"
                                        />
                                        Your browser does not support the audio
                                        element.
                                      </audio>
                                    </div>
                                  )}
                                </div>
                                <div>
                                  <span>
                                    {moment(Number(msg.timestamp)).format(
                                      "hh:mm A"
                                    )}
                                  </span>
                                  <Link to="#">
                                    <i className="icon ion-android-more-horizontal"></i>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </>
                    ))}
                  <label className="main-chat-time mt-2">
                    <span>{dateHeading}</span>
                  </label>
                </PerfectScrollbar>
              </div>
              <div className="d-flex justify-content-center">
                {img && (
                  <div
                    key={img}
                    className="image"
                    style={{ cursor: "pointer" }}
                  >
                    <Button
                      className="btn btn-ptimary ms-auto"
                      onClick={deleteSelectedImages}
                      style={{
                        position: "absolute",
                        zIndex: 1,
                        width: "10px",
                        height: "30px",
                      }}
                    >
                      <i
                        className="fa fa-times"
                        style={{ marginLeft: "-5px", marginBottom: "3px" }}
                      ></i>
                    </Button>
                    <img
                      src={URL.createObjectURL(img)}
                      alt="upload"
                      style={{
                        position: "relative",
                      }}
                      width="150px"
                      className="img-responsive2"
                    />
                  </div>
                )}
                {audio && (
                  <div
                    key={audio}
                    className="image"
                    style={{ cursor: "pointer" }}
                  >
                    <Button
                      className="btn btn-ptimary ms-auto"
                      onClick={deleteSelectedImages}
                      style={{
                        position: "absolute",
                        zIndex: 1,
                        width: "10px",
                        height: "30px",
                      }}
                    >
                      <i
                        className="fa fa-times"
                        style={{ marginLeft: "-5px", marginBottom: "3px" }}
                      ></i>
                    </Button>
                    <audio
                      src={URL.createObjectURL(audio)}
                      controls
                      style={{
                        position: "relative",
                      }}
                      className="audio-responsive"
                    />
                  </div>
                )}
                {recordedBlob && (
                  <div>
                    <Button
                      className="btn btn-ptimary ms-auto"
                      onClick={deleteSelectedImages}
                      style={{
                        position: "absolute",
                        zIndex: 1,
                        width: "10px",
                        height: "30px",
                      }}
                    >
                      <i
                        className="fa fa-times"
                        style={{ marginLeft: "-5px", marginBottom: "3px" }}
                      ></i>
                    </Button>
                    <audio
                      controls
                      src={recordedBlob.blob}
                      style={{
                        position: "relative",
                      }}
                    />
                  </div>
                )}
              </div>
              <div className="d-flex justify-content-center">
                {recordAudio && (
                  <div>
                    <Button
                      className="btn btn-ptimary ms-auto"
                      onClick={deleteSelectedImages}
                      style={{
                        position: "absolute",
                        zIndex: 1,
                        width: "10px",
                        height: "30px",
                      }}
                    >
                      <i
                        className="fa fa-times"
                        style={{ marginLeft: "-5px", marginBottom: "3px" }}
                      ></i>
                    </Button>
                    <ReactMic
                      record={isRecording}
                      onStop={onRecordingComplete}
                      mimeType="audio/webm"
                      visualSetting="sinewave"
                      strokeColor={"#121255"}
                    />
                    <button
                      className="btn"
                      onClick={startRecording}
                      disabled={isRecording}
                    >
                      <i className="fa fa-microphone text-muted"></i>
                    </button>
                    <button
                      className="btn"
                      onClick={stopRecording}
                      disabled={!isRecording}
                    >
                      <i className="fa fa-microphone-slash text-muted"></i>
                    </button>
                  </div>
                )}
              </div>

              <div className="main-chat-footer">
                <nav className="nav">
                  <div>
                    <input
                      type="file"
                      id="file"
                      style={{ display: "none" }}
                      onChange={(e) => setImg(e.target.files[0])}
                    />
                    <label
                      style={{ cursor: "pointer" }}
                      className="me-3 mt-2"
                      htmlFor="file"
                    >
                      <i className="fe fe-image nav-link"></i>
                    </label>
                  </div>

                  <Dropdown className="main-profile-menu-1">
                    <Dropdown.Toggle className="d-flex p-0" variant="default">
                      <i className="fa fa-microphone text-muted"></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dropdown-menu-chat">
                      <Dropdown.Item
                        onClick={() => {
                          uploadedAudio();
                        }}
                      >
                        <label
                          style={{ cursor: "pointer" }}
                          htmlFor="audio-file"
                        >
                          Upload Audio
                        </label>
                      </Dropdown.Item>
                      <Dropdown.Item onClick={recordedAudio}>
                        <div>Record Audio</div>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  <div style={{ cursor: "pointer" }}>
                    <input
                      type="file"
                      id="audio-file"
                      ref={audioFileRef}
                      style={{ display: "none" }}
                      accept="audio/*"
                      onChange={(e) => setAudio(e.target.files[0])}
                    />
                  </div>
                </nav>

                <input
                  className="form-control"
                  placeholder="Type your message here..."
                  type="text"
                  value={chatMessage}
                  onChange={(e) => setChatMessage(e.target.value)}
                  onKeyPress={handleKeyPress}
                />
                {!loading ? (
                  <>
                    <Link className="main-msg-send" onClick={sendMessage}>
                      <i className="far fa-paper-plane"></i>
                    </Link>
                  </>
                ) : (
                  <div className="d-flex justify-content-center">
                    <CircularProgress />
                  </div>
                )}
              </div>
            </div>
          </div>
        </Card>
        {/* </Col> */}
      </div>
    </Fragment>
  );
};

export default withLoader(ViewChats);
