import React from "react";
import { Navigate, useRoutes } from "react-router";
import { lazy } from "react";
import Report from "./components/Report/Report";
import Chat from "./components/Report/Chat";
import CounsellorChatList from "./components/Counsellors/CounsellorChatList";
import UserChatList from "./components/Users/UserChatList";
import GroupChat from "./components/Report/GroupChat";

const SuspenseLoader = (Component) => (props) =>
  (
    <React.Suspense fallback={<withLoader />}>
      <Component {...props} />
    </React.Suspense>
  );

const Dashboard = SuspenseLoader(
  lazy(() => import("./components/dashboard/dashboard.js"))
);

const Withdraw = SuspenseLoader(
  lazy(() => import("./components/Withdraw/Withdraw.js"))
);

const WithdrawAccount = SuspenseLoader(
  lazy(() => import("./components/Withdraw/WithdrawAccount.js"))
);

const Transactions = SuspenseLoader(
  lazy(() => import("./components/Transactions/Transactions"))
);

const Application = SuspenseLoader(
  lazy(() => import("./components/Application"))
);
const Users = SuspenseLoader(lazy(() => import("./components/Users/Users.js")));
const Counsellors = SuspenseLoader(
  lazy(() => import("./components/Counsellors/Counsellors"))
);
const CounsellorProfile = SuspenseLoader(
  lazy(() => import("./components/Counsellors/CounsellorProfile"))
);
const UserProfile = SuspenseLoader(
  lazy(() => import("./components/Users/UsersProfile.js"))
);
const Login = SuspenseLoader(
  lazy(() => import("../app/components/Authentication/Login.js"))
);
const NotFound = SuspenseLoader(
  lazy(() => import("./components/session/NotFound"))
);

function RedirectionWrapper({ to }) {
  const queryString = window.location.search;
  if (queryString) {
    return <Navigate to={`${to}${queryString}`} />;
  }
  return <Navigate to={to} />;
}

const routes = (isLoggedIn) => [
  {
    path: "/",
    element: isLoggedIn ? <Application /> : <RedirectionWrapper to="/login" />,
    children: [
      {
        element: <RedirectionWrapper to="/dashboard/" />,
        index: true,
      },
      {
        path: "/dashboard",
        element: <Dashboard />,
      },
      {
        path: "/counsellors",
        element: <Counsellors />,
      },
      {
        path: "/counsellors/counsellorprofile/:counsellorId",
        element: <CounsellorProfile />,
      },
      {
        path: "/counsellors/counsellorprofile/chatlist/:counsellorId",
        element: <CounsellorChatList />,
      },
      {
        path: "/users",
        element: <Users />,
      },
      {
        path: "/users/usersprofile/:userId",
        element: <UserProfile />,
      },
      {
        path: "/users/usersprofile/chatlist/:userId",
        element: <UserChatList />,
      },
      {
        path: "/groupchat/:id",
        element: <GroupChat />,
      },
      {
        path: "/transactions",
        element: <Transactions />,
      },
      {
        path: "/report",
        element: <Report />,
      },
      {
        path: "/report/chat/:id",
        element: <Chat />,
      },
      {
        path: "/withdraw",
        element: <Withdraw />,
      },
      {
        path: "/withdraw/:id",
        element: <WithdrawAccount />,
      },
    ],
  },
  {
    path: "/login",
    element: !isLoggedIn ? <Login /> : <RedirectionWrapper to="/dashboard/" />,
  },
  { path: "*", element: <NotFound /> },
  {
    index: true,
    element: <RedirectionWrapper to="/dashboard/" />,
  },
];

export default function Routes(props) {
  const { isLoggedIn } = props;
  return useRoutes(routes(isLoggedIn));
}
