import React from "react";
import { styled } from "@mui/styles";
import { Box } from "@mui/material";
import Loader1 from "../../../assets/img/loader.svg";

const StyledLoading = styled("div")(() => ({
  width: "100%",
  height: "100%",
  position: "absolute",
  top: 0,
  left: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#eaedf7",
  zIndex: 1,
  marginTop: "65px",
}));

const Loader = () => (
  <StyledLoading>
    <Box position="relative">
      <img src={Loader1} alt="Loader" />
    </Box>
  </StyledLoading>
);
Loader.propTypes = {};
Loader.defaultProps = {};
export default Loader;
