import serverCall from "../../serverCall";

const getAllCounsellors = () => {
  const response = serverCall.get(`/counsellor/admin/allCounsellor`);
  return response;
};

const getCounsellorProfile = (id) => {
  const response = serverCall.get(
    `/counsellor/admin/getCounsellorDetails?id=${id}`
  );
  return response;
};

const deleteCounsellor = (id) => {
  const response = serverCall.delete(`/counsellor/admin/deleteUser?id=${id}`);
  return response;
};

const updateStatus = async (id, status) => {
  const response = await serverCall.post(
    `/counsellor/admin/update-status?id=${id}&status=${status}`
  );
  return response;
};

const updateCounsellor = (id, data) => {
  const response = serverCall.put(
    `/counsellor/admin/update-counsellor/${id}`,
    data
  );
  return response;
};

const addCounsellor = (counsellor) => {
  const response = serverCall.post(
    `/counsellor/admin/add-counsellor`,
    counsellor
  );
  return response;
};

const getSpeciality = () => {
  const response = serverCall.get(`/counsellor/getSpeciality`);
  return response;
};

const getConfortableLanguage = () => {
  const response = serverCall.get(`/counsellor/getComfortableLanguage`);
  return response;
};

const deleteImage = (id, ImageUrl) => {
  const response = serverCall.delete(
    `/counsellor/admin/delete-counsellor-Image?counsellorId=${id}&ImageUrl=${ImageUrl}`
  );
  return response;
};

const CounsellorService = {
  getAllCounsellors,
  getCounsellorProfile,
  updateStatus,
  deleteCounsellor,
  addCounsellor,
  getSpeciality,
  getConfortableLanguage,
  updateCounsellor,
  deleteImage,
};

export default CounsellorService;
