import serverCall from "../../serverCall";
import { get } from "lodash";

const signIn = (userSignInRequest) => {
  const { email, password } = userSignInRequest;
  return serverCall
    .post(`/admin/login-admin?email=${email}&password=${password}`)
    .then((response) => {
      const data = get(response, "data", null);
      if (data.status === 400) {
        console.log("nothing to show");
      } else {
        localStorage.setItem("token", get(data, "accessToken", null));
        localStorage.setItem("email", get(data, "Admin.email", null));
      }
      return data;
    });
};

const logout = () => {
  localStorage.removeItem("token");
  localStorage.clear();
};

const AuthService = {
  signIn,
  logout,
};

export default AuthService;
